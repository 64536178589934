.NavBar {
  background-color: black;
  color: rgb(211, 211, 211);
  align-items: center;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
}
.Links {
  width: calc(150px + 100vmin);
  display: flex;
  justify-content: space-between;
  -webkit-flex-wrap: wrap; /* Safari 6.1+ */
  flex-wrap: wrap;
  margin-left: 10px;
  margin-right: 10px;
}
a:link {
  color: rgb(211, 211, 211);
  text-decoration: none;
}

/* visited link */
a:visited {
  color: rgb(211, 211, 211);
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: white;
  text-decoration: underline;
}

/* selected link */
a:active {
  color: white;
  text-decoration: underline;
}
.NavButton {
  border: none;
  background: none;
  font-family: 'Roboto Mono', monospace;
  display: flex;
  -webkit-transition-duration: 0.5s; /* Safari */
  transition-duration: 0.5s;
  color: rgba(255, 255, 255, 0.7);
}
.NavButton:focus {
  outline: none;
}
.NavButton:hover {
  text-decoration: underline;
  color: white;
}
.selected {
  text-decoration: underline;
  color: white;
}
