.Icon {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}
.Button {
  border: none;
  background: none;
  margin-left: 10px;
  display: flex;
  font-family: 'Roboto Mono', monospace;
  align-items: center;
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.3s;
}
.ProjectButton {
  border: none;
  background: none;
  margin-left: 10px;
  font-family: 'Roboto Mono', monospace;
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.3s;
  margin-top: 5px;
}
.Button:active,
.Button:focus {
  outline: none;
}
.Email:hover {
  fill: rgb(224, 0, 0);
}
.Github:hover {
  fill: rgb(53, 150, 58);
}
.LinkedIn:hover {
  fill: rgb(0, 102, 161);
}
.Resume:hover {
  fill: rgb(255, 166, 1);
}
.SocialsText:hover {
  text-decoration: underline;
}
