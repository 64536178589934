.ExperienceEntry {
  border-top: 2px solid black;
  margin-top: 40px;
  margin-bottom: 60px;
  padding-top: 10px;
}
.Experience {
  margin-left: 10%;
  margin-right: 10%;
}
.Link {
  color: #8a8a8a;
  font-weight: normal;
}
.Link:hover {
  color: black;
}
.Link:visited {
  color: inherit;
}
