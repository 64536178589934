.App {
  font-family: 'Roboto Mono', monospace;
}
.MedText {
  font-size: calc(10px + 1vmin);
}
.SmallText {
  font-size: calc(5px + 1vmin);
}
.BigText {
  font-size: calc(20px + 1vmin);
}
.Indent {
  text-indent: 30px;
}
.Bold {
  font-weight: bold;
}
.Shadow {
  box-shadow: 10px 10px 5px grey;
}
.nav-bar {
  background-color: black;
  justify-content: space-evenly;
  align-content: center;
}
.nav-item {
  color: 'white';
}
