.Profile {
  display: flex;
  margin-top: 70px;
  margin-left: 10%;
  margin-right: 10%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.profileImg {
  width: calc(75px + 25vmin);
  height: calc(75px + 25vmin);
}
.AboutMe {
  text-align: center;
  text-decoration: underline;
}
.Description {
  display: flex;
  flex-direction: column;
}

.Socials {
  display: inline-block;
  margin-top: 30px;
}
