.Projects {
  margin-left: 10%;
  margin-right: 10%;
}
.ProjectEntry {
  border-top: 2px solid black;
  margin-top: 40px;
  margin-bottom: 60px;
  padding-top: 10px;
}
.TextImage {
  display: flex;
  flex-direction: column;
}
.Alligator {
  width: calc(200px + 20vmin);
  height: calc(70px + 20vmin);
  margin-left: auto;
  margin-right: auto;
}
.Snake {
  width: calc(150px + 20vmin);
  height: calc(150px + 20vmin);
  margin-left: auto;
  margin-right: auto;
}
.Chair {
  width: calc(250px + 20vmin);
  height: calc(150px + 20vmin);
  margin-left: auto;
  margin-right: auto;
}
.ProjectTitle {
  display: flex;
  align-items: center;
}
