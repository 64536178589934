.Header {
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  background: repeating-linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2) 3px,
      rgba(0, 0, 0, 0.4) 3px,
      rgba(0, 0, 0, 0.4) 4px
    ),
    url('./../images/city-scape.jpg');
  background-size: 100%;
  background-position: bottom;
  -webkit-transition-duration: 0.7s; /* Safari */
  transition-duration: 0.7s;
  width: 100%;
}
.headerSmall {
  height: calc(70px + 10vmin);
}
.headerBig {
  height: calc(100px + 30vmin);
}
.Intro {
  padding: 20px;
  text-shadow: 5px 5px 2px black;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  border-radius: 10px;
  -webkit-transition-duration: 0.7s; /* Safari */
  transition-duration: 0.7s;
}
.Title {
  font-size: calc(3vmin + 25px);
  padding-bottom: 5px;
  border-bottom: 3px solid white;
}
.Description {
  font-size: calc(2vmin + 5px);
}
