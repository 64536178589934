.Skills {
  background-color: rgb(233, 232, 232);
  padding-left: 10%;
  padding-right: 10%;
  padding: 50px;
  margin-top: 50px;
}
.SkillsTitle {
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
  margin-bottom: 30px;
}
.SkillsList {
  text-align: center;
}
